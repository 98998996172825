
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        































































































h2 {
  margin-top: 0;
}
button {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
}
.player {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .avatar {
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
    width: 50px;
    height: 50px;

    img {
      display: block;
      width: 100%;
    }
  }
}
